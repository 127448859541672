import PropTypes from "prop-types"
import React, { useContext } from "react"
import classNames from "classnames"
import { ShareContext } from "../../context/ShareContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRetweet } from "@fortawesome/free-solid-svg-icons"
import Logo from "../logo/logo"
import Social from "../social/social"
import MenuButton from "../menu-button/menu-button"
import Navigation from "../navigation/navigation"
import "./sidebar.scss"

const Sidebar = ({ siteTitle }) => {
  const { collapsedShare, toggleShare } = useContext(ShareContext)
  return (
    <>
      <header className="main-header">
        <Logo />
        <MenuButton />
        <Social />
        {/* <div
          onClick={toggleShare}
          className={classNames("show-share showshare", {
            clshbt: collapsedShare,
          })}
        >
          <i className="fal fa-retweet"></i>
          <span>Share This</span>
        </div> */}
      </header>
      <Navigation />
    </>
  )
}

Sidebar.propTypes = {
  siteTitle: PropTypes.string,
}

Sidebar.defaultProps = {
  siteTitle: ``,
}

export default Sidebar
