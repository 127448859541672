/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.min.css"
import "./src/styles/reset.css"
import "./src/styles/fontawesome.css"
import "./src/styles/global.css"
import "./src/styles/color.css"
import "lightgallery.js/dist/css/lightgallery.css"

import React from "react"
import { LayoutProvider } from "./src/context/LayoutContext"
export function wrapRootElement({ element }) {
  return <LayoutProvider>{element}</LayoutProvider>
}

export const onInitialClientRender = () => {
  setTimeout(function () {
    const loader = document.getElementById("___loader")
    loader.classList.add("hidePreloader")
  }, 1000)
}
