import React, { useState, createContext } from "react"
const defaultState = {
  open: false,
  toggleNavigation: () => {},
}
const NavigationContext = createContext()
const NavigationContextProvider = props => {
  const [open, setNavigation] = useState(false)
  const toggleNavigation = () => setNavigation(!open)

  return (
    <NavigationContext.Provider
      value={{ open, toggleNavigation: toggleNavigation }}
    >
      {props.children}
    </NavigationContext.Provider>
  )
}

export { NavigationContextProvider, NavigationContext }
