import React, { Component } from "react"
import NavigationItem from "./navigation-item"
import classNames from "classnames"
import { navigate } from "gatsby"

class NavigationItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      marginLeft: 0,
      width: "1750px",
    }
  }

  uniqueId = () => {
    var ALPHABET =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    var ID_LENGTH = 8
    var rtn = ""
    for (var i = 0; i < ID_LENGTH; i++) {
      rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
    }
    return rtn
  }

  gotoItem = (url, e) => {
    e.preventDefault()
    const context = this.contextType
    if ("string" == typeof url) {
      this.props.toggleNavigation()

      navigate(url, { replace: true })
    }
    this.setState({ marginLeft: `${-350 * url}px` })
  }

  renderSubMenu = subNavigationItems => {
    if (subNavigationItems && subNavigationItems.length > 0) {
      return (
        <ul className="menu-panel" style={{ width: "350px" }}>
          {this.renderMenuItems(subNavigationItems)}
        </ul>
      )
    }
  }

  renderMenuItems = items => {
    return items.map((item, i) => {
      const { url, title, classes, navigationItems, root } = item

      if (root) {
        return (
          <ul key={i} className="menu-panel" style={{ width: "350px" }}>
            <li
              key={i}
              className={"string" == typeof url ? "nav" : "submen-dec-left"}
              key={this.uniqueId(`item-${i}-`)}
            >
              <a
                href={url}
                onClick={e => {
                  this.gotoItem(url, e)
                }}
                className="nav"
              >
                <NavigationItem title={title} />
              </a>

              {this.renderSubMenu(navigationItems)}
            </li>
          </ul>
        )
      } else {
        return (
          <li
            key={i}
            className={"string" == typeof url ? "nav" : "submen-dec"}
            key={this.uniqueId(`item-${i}-`)}
          >
            <a href={url} onClick={e => this.gotoItem(url, e)} className="nav">
              <NavigationItem title={title} />
            </a>

            {this.renderSubMenu(navigationItems)}
          </li>
        )
      }
    })
  }
  render() {
    const className = this.props.className
      ? `menu ${this.props.className}`
      : "menu"
    return (
      <div className="nav-inner-wrap">
        <nav className="nav-inner sound-nav sliding-menu" id="menu">
          <div
            className="sliding-menu-wrapper"
            style={{
              width: this.state.width,
              marginLeft: this.state.marginLeft,
              transition: "all 0.5s",
            }}
          >
            {this.renderMenuItems(this.props.navigationItems)}
          </div>
        </nav>
      </div>
    )
  }
}

export default NavigationItems
