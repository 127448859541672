import React, { useState } from "react"
import PropTypes from "prop-types"
import Crypt from "./crypt"
import { Link } from "gatsby"
const NavigationItem = ({ title }) => {
  const [status, setStatus] = useState(true)

  const entered = e => {
    setStatus(false)
  }
  const leaved = e => {
    setTimeout(function () {
      setStatus(true)
    }, 700)
  }
  const finished = () => {}
  return (
    <div onMouseEnter={entered} onMouseLeave={leaved}>
      {status ? <>{title}</> : <Crypt title={title} finished={finished} />}
    </div>
  )
}

export default NavigationItem
