import React, { Component } from "react"
import "./social.scss"

import SocialIcons from "./social-icons"
class Social extends Component {
  render() {
    return (
      <div className="header-social">
        <SocialIcons />
      </div>
    )
  }
}

export default Social
