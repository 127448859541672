import React from "react"

class NavigationCloseMouseButton extends React.Component {
  static defaultProps = {
    visible: true,
    offsetX: 0,
    offsetY: 0,
  }

  state = {
    xPosition: 0,
    yPosition: 0,
    mouseMoved: false,
    listenerActive: false,
  }

  componentDidMount() {
    this.addListener()
  }

  componentDidUpdate() {
    this.updateListener()
  }

  componentWillUnmount() {
    this.removeListener()
  }

  getTooltipPosition = ({ clientX: xPosition, clientY: yPosition }) => {
    this.setState({
      xPosition,
      yPosition,
      mouseMoved: true,
    })
  }

  addListener = () => {
    window.addEventListener("mousemove", this.getTooltipPosition)
    this.setState({ listenerActive: true })
  }

  removeListener = () => {
    window.removeEventListener("mousemove", this.getTooltipPosition)
    this.setState({ listenerActive: false })
  }

  updateListener = () => {
    if (!this.state.listenerActive && this.props.visible) {
      this.addListener()
    }

    if (this.state.listenerActive && !this.props.visible) {
      this.removeListener()
    }
  }

  render() {
    return (
      <div
        onClick={this.props.toggleNavigation}
        className="tooltip color-bg"
        style={{
          display:
            this.props.visible && this.state.mouseMoved ? "block" : "none",
          opacity: this.props.visible && this.state.mouseMoved ? "1" : "0",
          top: this.state.yPosition + this.props.offsetY,
          left: this.state.xPosition + this.props.offsetX,
        }}
      >
        Menü schliessen
      </div>
    )
  }
}

export default NavigationCloseMouseButton
