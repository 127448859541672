/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ShareContextProvider } from "../context/ShareContext"
import { ParallaxProvider } from "react-scroll-parallax"
import { LightgalleryProvider } from "react-lightgallery"
import { NavigationContextProvider } from "../context/NavigationContext"
import LayoutContext from "../context/LayoutContext"

import SlimLayout from "./layouts/slim"
import MainLayout from "./layouts/main"

const Layout = props => {
  const layoutContext = useContext(LayoutContext)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const slim = <SlimLayout children={props.children} data={data} />
  const main = <MainLayout children={props.children} data={data} />
  const current_layout = layoutContext.layout === "slim" ? slim : main
  return (
    <ShareContextProvider>
      <LightgalleryProvider
        lightgallerySettings={{
          // settings: https://sachinchoolur.github.io/lightgallery.js/docs/api.html
          useLeft: true,
          mode: "lg-zoom-in",
          download: false,
        }}
        galleryClassName="my_custom_classname"
      >
        <ParallaxProvider>
          <NavigationContextProvider>
            {current_layout}
          </NavigationContextProvider>
        </ParallaxProvider>
      </LightgalleryProvider>
    </ShareContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
