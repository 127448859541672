import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../../utils/transitionLinks/LinkFade"
import Img from "gatsby-image"
import "./logo.scss"
const Logo = () => {
  const logoQuery = graphql`
    query {
      logo: file(relativePath: { eq: "logo-with-border.png" }) {
        childImageSharp {
          fixed(width: 80, height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `
  const data = useStaticQuery(logoQuery)

  return (
    <Link to="/" className="logo-holder">
      <Img
        fixed={data.logo.childImageSharp.fixed}
        objectFit="cover"
        objectPosition="50% 50%"
        alt="Logo"
      />
    </Link>
  )
}

export default Logo
