import React, { useEffect } from "react"
import { useDencrypt } from "use-dencrypt-effect"

const Crypt = ({ title, finished }) => {
  const { result, dencrypt } = useDencrypt()

  useEffect(() => {
    const action = setInterval(() => {
      dencrypt(title)
    }, 0)

    return () => clearInterval(action)
  }, [])

  return <> {result} </>
}
export default Crypt
