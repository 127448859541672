import React, { Component, createContext } from "react"

// Note: I'm not passing a defaultValue to createContext
// as the whole app is going to be wrapped into the IntroProvider using the browser API wrapRootElement
const LayoutContext = createContext()

class LayoutProvider extends Component {
  state = {
    layout: "main",
  }

  update = newState => {
    if (JSON.stringify(newState) !== JSON.stringify(this.state)) {
      this.setState(newState)
    }
  }

  render() {
    return (
      <LayoutContext.Provider
        value={{
          layout: this.state.layout,
          update: this.update,
        }}
      >
        {this.props.children}
      </LayoutContext.Provider>
    )
  }
}
export default LayoutContext
export { LayoutProvider }
