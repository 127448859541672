/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"

import Sidebar from "../sidebar/sidebar"
const MainLayout = props => {
  return (
    <>
      <Sidebar siteTitle={props.data.site.siteMetadata.title} />
      {props.children}
    </>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
