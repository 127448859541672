import React, { useState, useContext } from "react"
import classNames from "classnames"
import { Transition } from "react-transition-group"
import NavigationItems from "./navigation-items"
import { NavigationContext } from "../../context/NavigationContext"
import logo from "../../images/logotext.png"
import NavigationCloseMouseButton from "../navigation-close-mouse-button/navigation-close-mouse-button"
import "./navigation.scss"
import Link from "../../utils/transitionLinks/LinkFade"

const duration = 300

const defaultStyleBackdrop = {
  transition: `all 600ms`,
  visibility: "hidden",
  opacity: 0,
  display: "flex",
}

const transitionStylesBackdrop = {
  entering: { visibility: "visible", opacity: 1 },
  entered: { visibility: "visible", opacity: 1 },
  exiting: { visibility: "hidden", opacity: 0 },
  exited: { visibility: "hidden", opacity: 0 },
}

const defaultStyleMenu = {
  transition: `all 600ms`,
  visibility: "hidden",
  opacity: 0,
  left: "-1864px",
}

const transitionStylesMenu = {
  entering: { opacity: 1, left: "0" },
  entered: { opacity: 1, left: "0" },
  exiting: { opacity: 0, left: "-1864px" },
  exited: { opacity: 0, left: "-1864px" },
}

const Navigation = () => {
  const [showTooltip, setToggleToolip] = useState(false) // returns two functions, one to set one to read

  const navigationItems = [
    {
      root: true,
      title: "Home",
      url: "/",
      classes: ["scroll-link"],
      navigationItems: [
        { title: "Projekte", url: "/projects", classes: [""] },
        { title: "Services", url: "/services", classes: [""] },
        { title: "Blog", url: "/blog", classes: [""] },
        { title: "DJ and Visuals", url: 1, classes: [""] },
        { title: "Kontakt", url: "/kontakt", classes: [""] },
      ],
    },
    {
      root: true,
      title: "Back",
      url: 0,
      classes: [""],
      navigationItems: [
        {
          title: "DJ Ian Dee Grees",
          url: "/music",
          classes: [""],
        },
        {
          title: "VJ Team Vitamin-D",
          url: "/visuals-vj-vitamin-d",
          classes: [""],
        },
      ],
    },
  ]

  const { open, toggleNavigation } = useContext(NavigationContext)

  function handleHoverOn(e) {
    setToggleToolip(true)
  }

  function handleHoverOff(e) {
    setToggleToolip(false)
  }

  return (
    <>
      <Transition in={open} timeout={duration}>
        {state => (
          <div
            onClick={toggleNavigation}
            className={classNames("nav-overlay")}
            style={{
              ...defaultStyleBackdrop,
              ...transitionStylesBackdrop[state],
            }}
          >
            <NavigationCloseMouseButton
              visible={showTooltip}
              toggleNavigation={toggleNavigation}
            ></NavigationCloseMouseButton>
          </div>
        )}
      </Transition>

      <Transition in={open} timeout={duration}>
        {state => (
          <div
            className="nav-holder"
            onMouseOver={handleHoverOff}
            onMouseLeave={handleHoverOn}
            style={{
              ...defaultStyleMenu,
              ...transitionStylesMenu[state],
              visibility: "visible",
            }}
          >
            <Link
              to="/"
              className="header-logo"
              cover
              direction="down"
              duration={2}
              entryOffset={80}
              bg="#2c2d32"
            >
              <img
                src={logo}
                alt="Logo"
                onClick={() => {
                  toggleNavigation()
                }}
              />
            </Link>
            <div className="nav-title">
              <span>Navigation</span>
            </div>

            <NavigationItems
              navigationItems={navigationItems}
              toggleNavigation={toggleNavigation}
            />
          </div>
        )}
      </Transition>
    </>
  )
}

export default Navigation
