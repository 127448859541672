import React, { useContext } from "react"
import "./menu-button.scss"
import classNames from "classnames"
import { NavigationContext } from "../../context/NavigationContext"

const MenuButton = () => {
  const { open, toggleNavigation } = useContext(NavigationContext)

  return (
    <div
      onClick={toggleNavigation}
      className={classNames(
        "nav-button",
        { "but-hol": !open },
        { cmenu: open }
      )}
    >
      <span className="nos"></span>
      <span className="ncs"></span>
      <span className="nbs"></span>
      <div className="menu-button-text">Menu</div>
    </div>
  )
}

export default MenuButton
